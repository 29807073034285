.strip-wrapper {
  background: #fafafa;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}
.strip {
  max-width: 300px;
  width: 100%;
  text-align: center;
}
.strip-img {
  width: 60px;
  height: 60px;
}
.strip-head {
  margin: 3px 0 0;
  text-transform: uppercase;
  font-size: 0.8em;
  color: #333;
}

/* Home banner styles */
[class^="number-slide"],
[class*=" number-slide"] {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  max-height: 100vh;
}

@media only screen and (max-width: 420px) {
  .strip-img {
    width: 40px;
    height: 40px;
  }
  [class^="number-slide"],
  [class*=" number-slide"] {
    height: 25vh;
  }
}
